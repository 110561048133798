define('ember-i18n/service', ['exports', 'ember', 'ember-i18n/stream', 'ember-i18n/locale', 'ember-i18n/add-translations', 'ember-i18n/get-locales'], function (exports, Ember, Stream, Locale, _addTranslations, getLocales) {

  'use strict';

  var get = Ember['default'].get;
  var makeArray = Ember['default'].makeArray;

  var Parent = Ember['default'].Service || Ember['default'].Object;

  // @public
  exports['default'] = Parent.extend(Ember['default'].Evented, {

    // @public
    // The user's locale.
    locale: null,

    // @public
    // A list of found locales.
    locales: Ember['default'].computed(getLocales['default']),

    // @public
    //
    // Returns the translation `key` interpolated with `data`
    // in the current `locale`.
    t: function t(key) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var locale = this.get('_locale');
      Ember['default'].assert("I18n: Cannot translate when locale is null", locale);
      var count = get(data, 'count');

      var defaults = makeArray(get(data, 'default'));

      defaults.unshift(key);
      var template = locale.getCompiledTemplate(defaults, count);

      if (template._isMissing) {
        this.trigger('missing', this.get('locale'), key, data);
      }

      return template(data);
    },

    // @public
    addTranslations: function addTranslations(locale, translations) {
      _addTranslations['default'](locale, translations, this.container);

      if (this.get('locale').indexOf(locale) === 0) {
        this.get('_locale').rebuild();
      }
    },

    _locale: Ember['default'].computed('locale', function () {
      var locale = this.get('locale');
      return locale ? new Locale['default'](this.get('locale'), this.container) : null;
    }),

    _buildLocaleStream: Ember['default'].on('init', function () {
      var _this = this;

      this.localeStream = new Stream['default'](function () {
        return _this.get('locale');
      });
    }),

    _notifyLocaleStream: Ember['default'].observer('locale', function () {
      this.localeStream.value(); // force the stream to be dirty
      this.localeStream.notify();
    })

  });

});